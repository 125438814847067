import {
    ArticleCard,
    CarouselSimilar,
    CompareButton,
    DealerName,
    Heading,
    HorizontalDrawer,
    PackageOptionsAndAccessories,
    PaymentOptions,
    PromotionCard,
    RecentViews,
    StickyBar,
    StickyPaymentOptions,
    Trade360Widget,
    VdpBreadcrumb,
    VehicleCertified,
    VehicleCtas,
    VehicleDescription,
    VehicleFeatures,
    VehicleHighlightCards,
    VehicleHistory,
    VehicleName,
    VehicleRebates,
    VehicleSpecs,
    VehicleTagline,
    VehicleSlider,
    VehicleSpecsExcerpt,
} from '@sm360/components'
import { QuoteModel, VehicleModel, WindowProxy, heightCalculator, resetParentsOverflow, setInnerHTML } from '@sm360/helpers'
import { useDeviceType, useTranslation } from '@sm360/hooks'
import React from 'react'

import { saveQuote, getCalculatorApiUrl } from '../../apis/showroom360/showroom360.service'
import { VdpContext } from '../../configs/vdp-view/state.manager'

const VdpAlpha = () => {
    const baseClass = 's360-p-inventoryVdp'
    const {
        state: {
            vehicle,
            dealerInfos,
            dealerInfos: { domain, orgId, orgUnitId, lang, orgUnitMakes },
            similarVehicles,
            hubName,
            vehiclePromo,
            showroom360ConfigVdp,
            quoteId,
            calculatorWidget,
            articleVehicle,
            tradeInWidget: { tradeInSidebar, tradeInAfterSimilar },
            paymentOptions: showroomPaymentOptions,
            XMSConfig: { pictureConfiguration, taggingConfigurations },
            XMSConfig,
        },
    } = React.useContext(VdpContext)

    // TODO: Delete when we have the section in Delta
    const showTrade360 = {
        displayTradeInSidebar: true,
        displayTradeInAfterSimilar: true,
    }

    const {
        newVehicle,
        make,
        model,
        year,
        trim,
        certified,
        consumption,
        odometer,
        frameDescription,
        bodyStyle,
        doors,
        passengers,
        engine,
        features,
        fuel,
        transmissionDetails,
        driveTrainDetails,
        exteriorColor,
        interiorColor,
        stockNo,
        serialNo,
        pastOwner,
        severelyDamagedVehicle,
        repairHistory,
        futureReparations,
        previousUse,
        description,
        paymentOptions,
        multimedia,
        pageViewSummary,
        tagline,
        primaryOrganizationUnit,
        vehicleId,
    } = vehicle

    const { detailNewHighlightCardConfigurations, detailUsedHighlightCardConfigurations } = showroom360ConfigVdp

    const highlights = newVehicle ? detailNewHighlightCardConfigurations : detailUsedHighlightCardConfigurations

    const recentViewData = taggingConfigurations?.find((config) => config.code === 'recent_view_count')

    const { t } = useTranslation()

    const { device } = useDeviceType()

    const [isCalculatorModalOpen, setIsCalculatorModalOpen] = React.useState(false)

    // TODO: Remove this const once get data as props from webauto or From Delta
    const isMultiDealerInventory = false

    React.useEffect(() => {
        if (WindowProxy) {
            WindowProxy.document.addEventListener('sm360.calculator.vdp.modal.close', () => onCalculatorModalOpen(false))
        }
    }, [])

    React.useEffect(() => {
        if (document) {
            resetParentsOverflow('[data-s360-sticky]')
        }
    })

    const onCalculatorModalOpen = (isOpen) => {
        setIsCalculatorModalOpen(isOpen)
    }

    const handleCalculatorCta = async () => {
        const calculatorWidget = await getCalculatorApiUrl(lang, vehicleId, dealerInfos, quoteId, true, true, domain)
        const dataStandalone = document.querySelectorAll('[data-standalone]')
        if (dataStandalone.length > 0) {
            dataStandalone.forEach(async (element) => {
                await setInnerHTML(element, calculatorWidget)
                setTimeout(() => setIsCalculatorModalOpen(true), 500)
            })
        }
    }

    const rebatesData = taggingConfigurations?.find((config) => config.code === 'total_rebates')
    const rebatesDataGovernment = taggingConfigurations?.find((config) => config.code === 'government_rebates')

    const newCtas = showroom360ConfigVdp?.detailNewCTAConfigurations?.filter(
        ({ active, statusName }) => active && statusName === 'new'
    )
    const usedCtas = showroom360ConfigVdp?.detailUsedCTAConfigurations?.filter(
        ({ active, statusName }) => active && statusName === 'used'
    )

    /**
     * Create public quote on similar model tile click and redirect
     */
    const handleSimilarModelTileClick = async (vehicleId, categoryName) => {
        const vehicleModel = vehicle instanceof VehicleModel ? vehicle : new VehicleModel(vehicle)

        const quote = new QuoteModel().set('vehicle', vehicleModel)
        const config = {
            orgId,
            orgUnitId,
        }
        const quoteId = await saveQuote(quote.getShowroomQuote(), config, lang)

        const vehicleVdpUrl = t(`vehicle.detailsWithQuote.${categoryName}`, {
            year,
            make: make?.slug,
            model: model?.slug,
            vehicleId,
            quoteId,
        })
        WindowProxy.location.href = vehicleVdpUrl
    }

    const dummyStickyCtas = [
        {
            buttonLabel: t('cta.addToGarage'),
            version: 'primary',
            slug: 'add_to_garage',
            id: 'addTogarage',
        },
        {
            buttonLabel: t('cta.reserveNow'),
            version: 'primary',
            slug: 'checkout',
            id: 'reserveNow',
        },
    ]

    const ctas = newVehicle ? newCtas : usedCtas

    return (
        <div className={`${baseClass}`} style={{ overflow: 'hidden' }}>
            <StickyBar wrapperClass={`${baseClass}__stickyBar ${baseClass}__wrapper`} targetClass={`.${baseClass}`} offset={200}>
                <div className={`${baseClass}__stickyBarInfosLeft ${device !== 'desktop' ? '-responsive' : ''}`}>
                    <VehicleName year={year} makeName={make?.name} modelName={model?.name} trimName={trim?.name} isFullName />

                    <div className={`${baseClass}__stickyBarVehicleRebates`}>
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -dealer`}
                            rebateValue={paymentOptions?.bestIncentives?.dealerRebates}
                            vehicleRebatesData={rebatesData}
                            layout='straight'
                            lang={lang}
                        />
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -government`}
                            rebateValue={paymentOptions?.bestIncentives?.governmentRebates}
                            vehicleRebatesData={rebatesDataGovernment}
                            layout='straight'
                            lang={lang}
                        />
                    </div>
                    {device === 'desktop' && (
                        <VehicleSpecsExcerpt
                            className={`${baseClass}__stickyBarVehicleSpecs`}
                            exteriorColor={vehicle?.exteriorColor}
                            interiorColor={vehicle?.interiorColor}
                            fuelName={fuel?.name}
                            fuelType={fuel?.slug}
                            odometer={odometer}
                            isNewVehicle={newVehicle}
                            lang={lang}
                        />
                    )}
                </div>
                {device === 'desktop' && (
                    <div className={`${baseClass}__stickyBarInfosRight`}>
                        <StickyPaymentOptions
                            newVehicle={newVehicle}
                            paymentOptions={paymentOptions}
                            isVDPView
                            purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                            showroomQuote={showroomPaymentOptions}
                            lang={lang}
                            handleCalculatorCta={handleCalculatorCta}
                            isCalculatorModalOpen={!!isCalculatorModalOpen}
                            vehicleId={vehicleId}
                            showRetailsTotal={false}
                        />

                        <div className={`${baseClass}__stickyBarVehicleCtas`}>
                            <VehicleCtas
                                vehicle={vehicle}
                                showroomQuote={showroomPaymentOptions}
                                category='inventory'
                                paymentOptions={paymentOptions}
                                dealerInfos={dealerInfos}
                                ctas={dummyStickyCtas}
                            />
                        </div>
                    </div>
                )}
            </StickyBar>

            <div className={`${baseClass}__wrapper`}>
                <div className={`${baseClass}__grid`}>
                    {/* TODO: The sr360 class above is temporary for demo purposes, remove later */}
                    {/* Section Top */}
                    <div className={`${baseClass}__navigation`}>
                        <VdpBreadcrumb
                            make={make?.name}
                            model={model?.name}
                            year={year}
                            trim={trim?.name}
                            hubCategory={newVehicle ? 'new' : 'used'} // Use vehicle status since we don't have Hub name for VDP
                            extraClasses={`${baseClass}__breadcrumb`}
                        />
                    </div>

                    <main className={`${baseClass}__main`}>
                        <div className='iv-vdpAlpha__container'>
                            <VehicleRebates
                                extraClasses={`${baseClass}__vehicleRebate -dealer`}
                                rebateValue={paymentOptions?.bestIncentives?.dealerRebates}
                                vehicleRebatesData={rebatesData}
                                layout='straight'
                                lang={lang}
                            />
                            <VehicleRebates
                                extraClasses={`${baseClass}__vehicleRebate -government`}
                                rebateValue={paymentOptions?.bestIncentives?.governmentRebates}
                                vehicleRebatesData={rebatesDataGovernment}
                                layout='straight'
                                lang={lang}
                            />

                            {device !== 'desktop' && (
                                <VehicleName
                                    year={year}
                                    makeName={make?.name}
                                    modelName={model?.name}
                                    trimName={trim?.name}
                                    isFullName
                                />
                            )}
                            <div className={`${baseClass}__pictureGallery`}>
                                <div className={`${baseClass}__pictureGalleryOverlay`}>
                                    <RecentViews
                                        recentViewCount={pageViewSummary?.recentViewCount}
                                        recentViewConfiguration={recentViewData}
                                    />
                                    <CompareButton
                                        vehicleId={vehicleId}
                                        labelUnchecked={t('vehicle.details.compare.add')}
                                        labelChecked={t('vehicle.details.compare.remove')}
                                        categoryName={'new'}
                                        pictoName={'add-car'}
                                    />
                                </div>

                                <VehicleSlider
                                    multimedia={multimedia}
                                    newVehicle={newVehicle}
                                    extraClasses={`${baseClass}__vehicleSlider`}
                                />
                            </div>

                            {device !== 'desktop' && (
                                <div className='vehicleInfo'>
                                    <div className='vehicleInfo__container'>
                                        <VehicleTagline tagline={tagline} extraClasses={`${baseClass}__tagline`} />

                                        <div className='vehicleTabs'>
                                            <PaymentOptions
                                                newVehicle={newVehicle}
                                                paymentOptions={paymentOptions}
                                                isVDPView
                                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                                showroomQuote={showroomPaymentOptions}
                                                lang={lang}
                                                handleCalculatorCta={handleCalculatorCta}
                                                isCalculatorModalOpen={!!isCalculatorModalOpen}
                                                vehicleId={vehicleId}
                                                showRetailsTotal={false}
                                            />
                                            <section className={`${baseClass}__vehicleSpecsExcerptMobile`}>
                                                <Heading Tag='h2' variant='heading3'>
                                                    {t('vehicle.specifications')}
                                                </Heading>

                                                <VehicleSpecsExcerpt
                                                    exteriorColor={vehicle?.exteriorColor}
                                                    interiorColor={vehicle?.interiorColor}
                                                    fuelName={fuel?.name}
                                                    fuelType={fuel?.slug}
                                                    odometer={odometer}
                                                    isNewVehicle={newVehicle}
                                                    lang={lang}
                                                />
                                            </section>

                                            <Heading
                                                extraClasses={`${baseClass}__ctaSteps__header`}
                                                Tag='h4'
                                                variant='heading3'
                                                subTitle={t('vehicle.cta.nextSteps.description')}
                                            >
                                                {t('vehicle.cta.nextSteps.title')}
                                            </Heading>

                                            <VehicleCtas
                                                calculatorWidget={calculatorWidget}
                                                vehicle={vehicle}
                                                showroomQuote={showroomPaymentOptions}
                                                category='inventory'
                                                paymentOptions={paymentOptions}
                                                dealerInfos={dealerInfos}
                                                ctas={ctas}
                                            />
                                            {isMultiDealerInventory && (
                                                <DealerName
                                                    extraClasses={`${baseClass}__dealerName`}
                                                    primaryOrganizationUnit={primaryOrganizationUnit}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <VehicleCertified
                                isCertified={certified}
                                dealerMakes={orgUnitMakes}
                                vehicleMakeSlug={make?.slug}
                                lang={lang}
                                imageSize='w300'
                            />

                            <VehicleSpecs
                                specs={{
                                    consumption,
                                    odometer,
                                    frameCategory: frameDescription?.frameCategory?.frameCategory,
                                    bodyStyle: bodyStyle?.name,
                                    doors,
                                    passengers,
                                    engineType: engine?.type,
                                    engineDescription: engine?.description,
                                    fuel: fuel?.name,
                                    transmission: transmissionDetails?.name,
                                    gears: transmissionDetails?.transGears,
                                    driveTrain: driveTrainDetails?.name,
                                    exteriorColor: exteriorColor?.color,
                                    interiorColor: interiorColor?.color,
                                    stockNumber: stockNo,
                                    serialNumber: serialNo,
                                }}
                                extraClasses={`${baseClass}__vehicleSpecs`}
                            />

                            <VehicleDescription description={description} model={model?.name} />

                            <PackageOptionsAndAccessories
                                optionsAndAccessories={paymentOptions?.packageOptionsAndAccessories}
                                displayLimit={false}
                                showEmptyElem={false}
                                headingClass='il-heading3'
                                extraClasses={`${baseClass}__vehicleOptions`}
                            >
                                <Heading extraClasses={`${baseClass}__header il-heading3`} Tag={'h2'} variant='heading3'>
                                    {t('vehicle.accessoriesAndOptions')}
                                </Heading>
                            </PackageOptionsAndAccessories>

                            <VehicleFeatures features={features} extraClasses={`${baseClass}__vehicleFeatures`} />

                            {highlights?.length > 0 && (
                                <VehicleHighlightCards vehicleInventory={vehicle} highlights={highlights} quoteId={quoteId} />
                            )}

                            {!newVehicle && (
                                <VehicleHistory
                                    vehicle={vehicle}
                                    domain={domain}
                                    pastOwner={pastOwner}
                                    severelyDamagedVehicle={severelyDamagedVehicle}
                                    repairHistory={repairHistory}
                                    futureReparations={futureReparations}
                                    previousUse={previousUse}
                                />
                            )}

                            <div className={`${baseClass}__similar`}>
                                <CarouselSimilar
                                    vehicleList={similarVehicles}
                                    hubName={hubName}
                                    pictureConfiguration={pictureConfiguration}
                                    onSimilarModelTileClick={(vehicleId, categoryName) =>
                                        handleSimilarModelTileClick(vehicleId, categoryName)
                                    }
                                    XMSConfig={XMSConfig}
                                    dealerInfos={dealerInfos}
                                    saveQuote={saveQuote}
                                />
                            </div>

                            {showTrade360.displayTradeInAfterSimilar && (
                                <Trade360Widget
                                    extraClasses={`${baseClass}__trade360`}
                                    widgetId={'tradeInAfterSimilar'}
                                    tradeInWidget={tradeInAfterSimilar}
                                    elementNodeName={'data-trade-standalone'}
                                    elementNodeValue={'tradeInAfterSimilar'}
                                />
                            )}
                        </div>
                    </main>

                    {device === 'desktop' && (
                        <aside className={`${baseClass}__aside`}>
                            <section style={{ top: heightCalculator('data-header') }} data-s360-sticky>
                                <div className='vehicleInfo'>
                                    <div className='vehicleInfo__container'>
                                        <VehicleName
                                            year={year}
                                            makeName={make?.name}
                                            modelName={model?.name}
                                            trimName={trim?.name}
                                            isFullName
                                            extraClasses={`${baseClass}__vehicleName`}
                                        />

                                        <VehicleTagline tagline={tagline} extraClasses={`${baseClass}__tagline`} />

                                        <div className='vehicleTabs'>
                                            <PaymentOptions
                                                newVehicle={newVehicle}
                                                paymentOptions={paymentOptions}
                                                isDetailView
                                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                                showroomQuote={showroomPaymentOptions}
                                                isVDPView
                                                lang={lang}
                                                calculatorWidget={calculatorWidget}
                                                showRetailsTotal={false}
                                                handleCalculatorCta={handleCalculatorCta}
                                                isCalculatorModalOpen={!!isCalculatorModalOpen}
                                                vehicleId={vehicleId}
                                            />

                                            <Heading
                                                extraClasses={`${baseClass}__ctaSteps__header`}
                                                Tag='h4'
                                                variant='heading3'
                                                subTitle={t('vehicle.cta.nextSteps.description')}
                                            >
                                                {t('vehicle.cta.nextSteps.title')}
                                            </Heading>

                                            <VehicleCtas
                                                calculatorWidget={calculatorWidget}
                                                vehicle={vehicle}
                                                showroomQuote={showroomPaymentOptions}
                                                category='inventory'
                                                paymentOptions={paymentOptions}
                                                dealerInfos={dealerInfos}
                                                ctas={ctas}
                                            />
                                            {isMultiDealerInventory && (
                                                <DealerName
                                                    extraClasses={`${baseClass}__dealerName`}
                                                    primaryOrganizationUnit={primaryOrganizationUnit}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {showTrade360.displayTradeInSidebar && (
                                <Trade360Widget
                                    extraClasses={`${baseClass}__trade360`}
                                    widgetId={'tradeInSidebar'}
                                    tradeInWidget={tradeInSidebar}
                                    elementNodeName={'data-trade-standalone'}
                                    elementNodeValue={'tradeInSidebar'}
                                />
                            )}

                            {newVehicle && (vehiclePromo?.promotions.length > 0 || articleVehicle.length > 0) && (
                                <section className={`${baseClass}__promotionArticle`}>
                                    {vehiclePromo?.promotions.length > 0 && (
                                        <PromotionCard
                                            extraClasses={`${baseClass}__promotion`}
                                            promotion={vehiclePromo.promotions[0]}
                                        />
                                    )}
                                    {articleVehicle.length > 0 && (
                                        <ArticleCard extraClasses={`${baseClass}__article`} article={articleVehicle[0]} />
                                    )}
                                </section>
                            )}
                        </aside>
                    )}
                </div>
            </div>
            {device !== 'desktop' && (
                <HorizontalDrawer
                    SmallContent={() => (
                        <>
                            <StickyPaymentOptions
                                newVehicle={newVehicle}
                                paymentOptions={paymentOptions}
                                isVDPView
                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                showroomQuote={showroomPaymentOptions}
                                lang={lang}
                                calculatorWidget={calculatorWidget}
                                showRetailsTotal={false}
                                extraClasses={`${baseClass}__mobileFooterPaymentOptions`}
                            />

                            <VehicleCtas
                                vehicle={vehicle}
                                showroomQuote={showroomPaymentOptions}
                                category='inventory'
                                paymentOptions={paymentOptions}
                                dealerInfos={dealerInfos}
                                ctas={dummyStickyCtas}
                                extraClasses={`${baseClass}__mobileFooterCtas`}
                            />
                        </>
                    )}
                    handleCalculatorCta={handleCalculatorCta} // TODO: Remove handleCalculatorCta props to open calculator once we have Drawer content
                >
                    <p>Big Content (future payment calculator)</p>
                </HorizontalDrawer>
            )}
            <div className={`${baseClass}__calculator`} data-standalone='paymentCalculator'></div>
        </div>
    )
}

export default VdpAlpha
